<template>
  <el-dropdown
      @command="change"
      class="right-menu-item"
      trigger="click"
  >
    <span>{{ currentLang }}</span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
          :class="currentLang===lang.shortcut ? 'dropdownItemActive' : ''"
          :command="lang.shortcut"
          :key="lang.id"
          v-for="lang in languages"
      >
        {{ lang.name }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      languages: [],
      currentLang: '',
    };
  },
  async beforeMount() {
    let lang = this.$store.getters['auth/isAuthenticated'] ? localStorage.getItem('langLocal') : localStorage.getItem('langGlobal');
    this.currentLang = lang !== null ? lang : 'en';
    this.getLanguages();
    if (this.$store.state.language) {
      this.change(this.$store.state.language);
    }
  },
  methods: {
    getLanguages() {
      axios.get('languages').then((response) => {
        this.languages = response.data.data;
      });
    },
    change(lang) {
      this.$emit('changeLanguage', lang);
      this.currentLang = lang;
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>
.dropdownItemActive {
  color: #000;
  font-weight: 600;
}

.icon-navbar {
  font-size: 18px !important;
}

.right-menu-item {
  font-size: 14px;
  color: white;
  text-transform: uppercase;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;

  span {
    cursor: pointer;
    display: flex;
    align-items: center;
    outline: none;
  }
}
</style>
